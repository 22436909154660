// import logo from './logo.svg';
import React, {useState, useEffect} from 'react';
import './App.css';

import axios from 'axios';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { NumericFormat } from 'react-number-format';
import { PatternFormat } from 'react-number-format';

import PhoneIcon from '@mui/icons-material/Phone';
import { Box } from '@mui/material';
import { Label, ShoppingCartCheckoutRounded } from '@mui/icons-material';

function App() {

  console.log("REACT_APP_ENV: " + process.env.REACT_APP_ENV);

  const url = new URL(window.location.href);
  const urlParts = url.pathname.split('/');
  
  const contactId = url.searchParams.get('cid'); //urlParts[urlParts.length-1];
  const csid = url.searchParams.get('csid'); //urlParts[urlParts.length-2];

  const [validationError, setValidationError] = useState(contactId == null || contactId == '');

  //console.log("app load - url: " + url.pathname + " : id: " + contactId);
  
  // const [isValidPhone, setIsValidPhone] = useState(false);
  // const [mobilePhone, setMobilePhone] = useState('');
  // const [isConsenting, setIsConsenting] = useState(false);
  //const [consent, setConsent] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  //const [isConsenting, setIsConsenting] = useState(false);
  let step;

  //document.title = "Away Service Consent";

  //<meta name="msapplication-TileImage" content="https://www.lifeline.com/wp-content/uploads/2022/05/cropped-lifeline-favicon-270x270.png">

  // useEffect(() => {    
  //   document.title = `You're on step: ${currentStep} - ${isConsenting}`;
  // }, [currentStep]);

  function next(step) {
    setCurrentStep(step);

    //console.log('You clicked step: ' + step + ". Current step: " + currentStep);
  }

  function RenderStep(){
    //console.log('renderStep: ' + currentStep);
    if(validationError)
    {
      return <ValidationError/>

    }

    switch(currentStep){
      case 0:
        return <RenderStart/>
        break;

      case 1:
        return <RenderConsent next={next} contactId={contactId} csid={csid}/>
        break;

      case 2:
          return <RenderDone/>
          break;        
    }
  };

  function ValidationError()
  {
    return (
      <Card variant="outlined" className='App-header'>
        <h1>Invalid Request</h1>
        <p>The requested resource is invalid or missing. Please check the link and try again.</p>
      </Card>
    );
  };

  function RenderStart()
  {
    return (
      <Card variant="outlined" className='App-header'>
        <p>
        Welcome to the Lifeline Away Service SMS consent page for text message notifications when your loved one(s) leave and return to their home. 
        To review the terms and conditions of the Lifeline Away Service, please the link provided - <a href="https://www.lifeline.com/terms-of-use/">Terms and Conditions</a>
        </p>
        <p>
          When you're ready, click "Continue" below to proceed.
        </p>
        <Button variant="contained" onClick={() => next(1)}>Continue</Button>
      </Card>
    );
  };

  function isPhoneValid(phone){
    var isValid = phone.match(/^(\d{3}){1}-(\d{3}){1}-(\d{4})$/);
    //console.log("valid: " + isValid);

    return isValid;
  };



  // function RenderConsenting()
  // {
  //   return (
  //     <div>
  //       {/* <hr/> */}
  //       <p><span style={{color: 'red'}}>*</span> Please enter your 10-digit mobile phone number below:</p>
  //       {/* <FormControl size='small' margin='none' sx={{ m: 1, minWidth: 275, display: 'inline-block', alignItems: 'center', justifyContent: 'center'  }}> */}
  //         <InputLabel htmlFor="outlined-adornment-phone">Phone</InputLabel>
  //         {/* <PatternFormat format="###-###-####" sx={{fontSize: '20pt'}} allowEmptyFormatting mask="_" />; */}
  //         <OutlinedInput
  //           id="outlined-adornment-phone"
  //           onChange={(e) => setMobilePhone(e.target.value)}
  //           value={mobilePhone}
  //           startAdornment={<InputAdornment position="start"><PhoneIcon /></InputAdornment>}
  //           label="Phone"
  //         />
  //       {/* </FormControl> */}
  //       {/* <hr/> */}
  //     </div>
  //   );
  // };

  // const PhoneInputComponent = ({ setState, state }) => (
  //   <div style={{ marginTop: '25px', marginBottom: '25px'}}>
  //     <hr/>
  //     <p><span style={{color: 'red'}}>*</span>Please enter your 10-digit mobile phone number below:</p>
  //     <FormControl sx={{ m: 1, marginTop: '5px', marginBottom: '35px' }}>
  //       <InputLabel htmlFor="outlined-adornment-phone">Phone</InputLabel>
  //         <OutlinedInput
  //           id="outlined-adornment-phone"
  //           // onChange={(e) => setMobilePhone(e.target.value)}
  //           onChange={e => {setState(formatPhone(e.target.value));}}
  //           value={state}
  //           startAdornment={<InputAdornment position="start"><PhoneIcon /></InputAdornment>}
  //           label="Phone"
  //           sx={{ maxWidth: 350}}
  //         />
  //     </FormControl>
  //     <hr/>
  //   </div>
  //   // <form>
  //   //   <label htmlFor='phoneInput'>
  //   //     <input
  //   //       type="text"
  //   //       id='phoneInput'
  //   //       value={state}
  //   //       placeholder='Mobile Phone Number'
  //   //       onChange={e => setState(formatPhone(e.target.value))}
  //   //     />
  //   //   </label>
  //   // </form>
  // );
  
  // function usePhoneInput(defaultState) {
  //   //const [state, setState] = useState(defaultState);
  //   const [mobilePhone, setMobilePhone] = useState('');

  //   return [
  //     isPhoneValid(mobilePhone),
  //     <PhoneInputComponent state={mobilePhone} setState={setMobilePhone} />,
  //     setMobilePhone
  //   ];
  // }
  
  function RenderDone()
  {
    return (
      <Card variant="outlined" className="App-header">
        <Alert severity="success">Thank you!</Alert>  
      </Card>
    );
  };

  return (
    <div className="App">
      {/* <b>Step: {currentStep}</b>
      | <b>Consent: {consent}</b>
      <br/> */}
      <RenderStep />
    </div>
  );
}


function RenderConsent(props)
{
  const defaultErrorMessage = 'An error occurred while saving your selections.';
  const defaultSupportMessage = 'Please try again or call support for help at 888-767-5586.';
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);
  const [hasError, setHasError] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [mobilePhone, setMobilePhone] = useState('');
  const [isConsenting, setIsConsenting] = useState(false);
  const [consent, setConsent] = useState('');
  //console.log("renderconent");

  function changeConsent(e)
  {
    setConsent(e.target.value);
  }

  function formatPhone(phone){
    //var phone = e.target.value;
    
    phone = phone.slice(0,12)
                 .replace(/\D+/g, '')
                 .replace(/^(\d{1,3}){1}$/, '$1')
                 .replace(/^(\d{3}){1}-?(\d{1,3}){1}-?$/, '$1-$2')
                 .replace(/^(\d{3}){1}-?(\d{3}){1}-?(\d{1,4}){1}$/, '$1-$2-$3');
    
    var isValid = /^(\d{3}){1}-(\d{3}){1}-(\d{4})$/.test(phone);
    //console.log("valid: " + isValid);
    setIsValidPhone(isValid);

    return phone;
  };

  //const [phoneValid, PhoneInput] = usePhoneInput("");

  function validatePhoneMatches(){
    //const url = 'https://func-mastermind-integration-stage.azurewebsites.net/api/SaveContactOption_Function'; //'http://localhost:7264/api/SaveContactOption_Function';

    let url = process.env.REACT_APP_VALIDATE_PHONE_URL ?? 'https://func-mastermind-integration-stage.azurewebsites.net/api/ValidatePhoneMatch_Function';
    
    url = `${url}/${props.contactId}/${mobilePhone}`;

    console.log("GET: " + url);
    setHasError(false);
    setIsValidating(true);

    return axios.get(url, (req, res) => { res.set('Access-Control-Allow-Origin', window.location.origin + ':' + window.location.port);})
         .then(response => {

          const success = response.data.success;

          if(!success){
            setErrorMessage("Phone number entered does not match the number on record.");
            setHasError(true);
            setIsValidating(false);
          }

          return success;
         })
         .catch(error => {
            console.error(error);
            setHasError(true);
            setErrorMessage(error);
            setIsValidating(false);
          } );
  };

  function sendOption(){
    //const url = 'https://func-mastermind-integration-stage.azurewebsites.net/api/SaveContactOption_Function'; //'http://localhost:7264/api/SaveContactOption_Function';

    const url = process.env.REACT_APP_CONSENT_URL ?? 'https://func-mastermind-integration-stage.azurewebsites.net/api/SaveContactOption_Function';
    
    const request = {
      contact_No: props.contactId,
      //cs_no: props.csid,
      option_Id: 'CONSENT_AWAY_SMS',
      option_Value: consent,
      change_user: 6,
      //change_date: '2023-11-01 12:03:00',
      change_flag: 3   
    };

    console.log("POST: " + JSON.stringify(request));
    setHasError(false);
    setIsValidating(true);

    axios.post(url, request, (req, res) => { res.set('Access-Control-Allow-Origin', window.location.origin + ':' + window.location.port);})
         .then(response =>  props.next(2))
         .catch(error => {
            console.error(error);
            setHasError(true);
            setIsValidating(false);
            setErrorMessage(defaultErrorMessage);
          } );
  };

  async function verifyAndSave(){
    setHasError(false);

    var isValid = isConsenting && await validatePhoneMatches();

    if(isValid || !isConsenting){
      await sendOption();
    }
  };  

  return (
    <Card variant="outlined" className='App-header'>
      <p style={{textAlign: 'left'}}>
        <span style={{color: 'red'}}>* </span> 
        At Lifeline Away Service, we offer the option of receiving email and text message notifications
        when your loved one(s) leave or return to their home. To comply with the Telephone Consumer Protection Act (TCPA),
        we need your express consent before we can send you any text message notifications. Please select <b>"Yes"</b> if 
        you would like to receive text message notifications, or <b>"No"</b> if you do not wish to receive them.
        Kindly be aware that selecting <b>"No"</b> will still result in receiving email notifications from Lifeline Away Service.
      </p>
      <p>Please make your selection from the dropdown list below:</p>
      <div style={{alignItems: 'center'}}>
        <FormControl sx={{ m: 1, minWidth: 275, textAlign: 'center' }}>
          <InputLabel id="consent-select-label">Consent</InputLabel>
          <Select
            // labelId="consent-select-label"
            id="consent-select"
            displayEmpty
            value={consent}
            label="Consent"
            onChange={changeConsent}
          >
            <MenuItem disabled value="">
              <em>Please select</em>
            </MenuItem>
            <MenuItem value={'Y'}>Yes</MenuItem>
            <MenuItem value={'N'}>No</MenuItem>
          </Select>
        </FormControl>
      </div>
      {consent != 'Y'
         ? null
         : 
        //  <div>
          // <hr/>
         <div style={{borderTopStyle: 'solid', marginTop: '25px', marginBottom: '25px', alignItems: 'center', display: 'block', marginLeft: 'auto', marginRight: 'auto', display: 'contents' }}>
            {/* <div style={{ marginTop: '25px', marginBottom: '25px'}}> */}
              {/* <hr/> */}
              {/* <div style={{alignItems: 'center'}}> */}
              <p><span style={{color: 'red'}}>*</span> Please enter your 10-digit mobile phone number below:</p>
              <FormControl sx={{ marginTop: '10px', marginBottom: '35px', textAlign: 'center'}}>
                <InputLabel htmlFor="outlined-adornment-phone">Phone</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-phone"
                    // onChange={(e) => setMobilePhone(e.target.value)}
                    onChange={e => {setMobilePhone(formatPhone(e.target.value));}}
                    value={mobilePhone}
                    startAdornment={<InputAdornment position="start"><PhoneIcon /></InputAdornment>}
                    label="Phone"
                    sx={{ maxWidth: 350}}
                  />
              </FormControl>
              {/* </div> */}
              {/* <hr/> */}
            {/* </div> */}
            <div  style={{ paddingTop: '25px', paddingBottom: '25px'}}>
              <Typography variant="body">
                <span style={{color: 'red',}}>* </span>
                Please click the checkbox below to confirm that you have read and agree to the Lifeline Away Service 
                SMS Terms and Conditions, and that you consent to receive text messages for this service. Please note that by submitting this entry, it will overwrite 
                any previous preferences you may have submitted. This submission will be updated for all individuals where you are listed as the Away Service Contact.
              </Typography>
              <Box style={{ mx: '2px', margin: '25px', padding: '15px', alignItems: 'center', justifyContent: 'center', borderRadius: 5, borderStyle: 'solid', borderColor: 'AliceBlue', backgroundColor: 'GhostWhite', '&:hover': { bgcolor: '#def',},}}>
                <Stack spacing={2} direction="row" style={{ mx: '2px', alignItems: 'center', justifyContent: 'center' }}>
                  <Checkbox checked={isConsenting} onChange={(e) => setIsConsenting(e.target.checked)}/>
                  <Typography variant="body">I have read and agree to the Lifeline Away Service SMS Terms and Conditions</Typography>
                </Stack>
              </Box>
            </div>
            </div>
            // <hr/>            
           //</div>
        }
      <Stack spacing={2} direction="row" style={{ mx: '2px', margin: '25px' , alignItems: 'center', justifyContent: 'center'}}>
        <Button variant="contained" size='large' disabled={isValidating} onClick={() => props.next(0)}>&#8592; Back</Button>
        <Button variant="contained"  size='large' disabled={(consent == '' || consent == 'Y') && (!isConsenting || !isValidPhone || isValidating)} onClick={verifyAndSave}>Submit</Button>        
      </Stack>
      { hasError ? <Alert severity="error">{errorMessage} {defaultSupportMessage}</Alert> : null}
      </Card>
  );
};

export default App;
